import React from 'react';

/**
 * @returns {JSX.Element} RedoIcon SVG
 */
export default function RedoIcon() {
  return (
    <svg
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.7272 11.5626C27.1661 7.24921 21.2816 5.63362 16.0173 7.52399C10.7529 9.41435 7.24046 14.4043 7.23682 19.9977V23.6197'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.854 29.0995C14.5835 33.0513 20.3463 34.3285 25.3962 32.3224C30.4461 30.3162 33.7615 25.4326 33.7624 19.9988V17.5874'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.41406 18.7932L7.23688 23.616L12.0597 18.7932'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M38.5853 22.4102L33.7625 17.5874L28.9397 22.4102'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
